import overlaybg from '../assets/overlaybg.png';
import spoon from '../assets/spoon.svg';
import welcome from '../assets/welcome.png';
import welcomeMin from '../assets/welcome-min.png';
import welcomeMinHtml from '../assets/welcome-min-html.webp';
import findus from '../assets/findus.png';
import laurels from '../assets/laurels.png';
import award01 from '../assets/award01.png';
import award02 from '../assets/award02.png';
import award03 from '../assets/award03.png';
import award05 from '../assets/award05.png';
import sign from '../assets/sign.png';
import quote from '../assets/quote.png';
import QLogo from '../assets/QLogo.jpg';
import gericht from '../assets/gericht.png';
import MenuLunch from '../assets/MenuLunchHTML.webp';
import MenuDinner from '../assets/MenuHTML.webp';
import MenuDrinks from '../assets/MenuDrinksHTML.webp';
import LandingPage from '../assets/LandingPage.png';
import WelcomeImage from '../assets/WelcomeImage.png';
import logoQ from '../assets/logoQ.png';
import FrontHTML from '../assets/Gallery/Final/Front.webp';
import Grill2HTML from '../assets/Gallery/Final/Grill2.webp';
import Grill3HTML from '../assets/Gallery/Final/Grill3.webp';
import Grill4HTML from '../assets/Gallery/Final/Grill4.webp';
import ArtHTML from '../assets/Gallery/Final/Art.webp';
import BarHTML from '../assets/Gallery/Final/Bar.webp';
import Store1HTML from '../assets/Gallery/Final/Store1.webp';
import TableHTML from '../assets/Gallery/Final/Table.webp';
import Table2HTML from '../assets/Gallery/Final/Table2.webp';

export default {

  Grill2HTML,
  Grill3HTML,
  Grill4HTML,
  Store1HTML,
  BarHTML,
  ArtHTML,
  TableHTML,
  FrontHTML,
  Table2HTML,
  overlaybg,
  spoon,
  welcome,
  welcomeMin,
  welcomeMinHtml,
  findus,
  laurels,
  award01,
  award02,
  award03,
  award05,
  sign,
  quote,
  gericht,
  QLogo,
  LandingPage,
  WelcomeImage,
  MenuLunch,
  MenuDinner,
  MenuDrinks,
  logoQ,
};
