import React, { useRef } from 'react';
import Toastify from 'toastify-js';
import 'toastify-js/src/toastify.css';
import emailjs from '@emailjs/browser';
import './Newsletter.css';

const Newsletter = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_x1cljeq',
        'template_yg2fbix',
        form.current,
        'vFrwg9ZUMg4jfErzH',
      )
      .then(
        () => {
          Toastify({
            text: 'Thank You For Submitting Feedback',
            offset: {
              x: 10, // horizontal axis - can be a number or a string indicating unity. eg: '2em'
              y: 0, // vertical axis - can be a number or a string indicating unity. eg: '2em'
            },
            duration: 6000,
            newWindow: true,
            gravity: 'top', // `top` or `bottom`
            position: 'right', // `left`, `center` or `right`
            stopOnFocus: true, // Prevents dismissing of toast on hover
            style: {
              background: ' #0C0C0C',
            },
            onClick() {}, // Callback after click
          }).showToast();
          document.getElementById('ref_form').reset();
        },
        () => {
        },
      );
  };
  return (
    <div className="app__newsletter">
      <div className="app__newsletter-heading">
        <h1 className="headtext__cormorant">Give us Feedback</h1>
        <p className="p__opensans">Tell us about your Experince</p>
        <p className="p__opensans">Let us know how we can improve</p>
      </div>

      <div className="app__newsletter-input flex__center">
        <form id="ref_form" ref={form} onSubmit={sendEmail}>
          <h3 className="headtext__cormorant" style={{ fontSize: '1.2rem', lineHeight: '1.5' }}>Full Name</h3>
          <input required type="text" name="user_name" placeholder="Enter your full name" />
          <h3 className="headtext__cormorant" style={{ fontSize: '1.2rem', lineHeight: '1.5' }}>Email</h3>
          <input required type="email" name="user_email" placeholder="Enter your email address" />
          <h3 className="headtext__cormorant" style={{ fontSize: '1.2rem', lineHeight: '1.5' }}>Subject Matter</h3>
          <textarea required placeholder="Subject here" name="message" />
          <div style={{ textAlign: 'center', marginTop: '1rem' }}>
            <button type="submit" className="custom__button">Submit</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Newsletter;

