import React from 'react';
import { FindUs, Footer, Gallery, Header, SpecialMenu } from './container';
import { Navbar } from './components';
import './App.css';

const App = () => (
  // eslint-disable-next-line react/style-prop-object
  <div>
    <Navbar />
    <Header />
    <SpecialMenu />
    <Gallery />
    <FindUs />
    <Footer />
  </div>
);

export default App;
