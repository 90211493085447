import React from 'react';
import { FiFacebook } from 'react-icons/fi';

import { images } from '../../constants';
import './Footer.css';

const Footer = () => (
  <div className="app__footer section__padding" id="login">
    <div className="app__footer-links">
      <div className="app__footer-links_contact">
        <h1 className="app__footer-headtext">Contact Us</h1>
        <p className="p__opensans">872 Buford Rd, Cumming, GA, United States, Georgia</p>
        <p className="p__opensans">(770) 406-8441</p>
        <p className="p__opensans">qkoreanbbqcumming@gmail.com</p>
      </div>

      <div className="app__footer-links_logo">
        <img src={images.logoQ} alt="footer_logo" style={{ marginBottom: '5rem' }} />
        <p className="p__opensans">&quot;A Taste of Korea.&quot;</p>
        <img src={images.spoon} className="spoon__img" style={{ marginTop: 15 }} />
        <div className="app__footer-links_icons">
          <a href="https://www.facebook.com/QKoreanBBQ" target="_blank" rel="noreferrer"> <FiFacebook /></a>
        </div>
      </div>

      <div className="app__footer-links_work">
        <h1 className="app__footer-headtext">Working Hours</h1>
        <p className="p__opensans">Sunday-Thursday:</p>
        <p className="p__opensans">11:30 am - 10:00 pm</p>
        <p className="p__opensans">Friday-Saturday:</p>
        <p className="p__opensans">11:30 am - 11:00 pm</p>
      </div>
    </div>

    <div className="footer__copyright">
      <p className="p__opensans">2023 Q Steak House. All Rights reserved.</p>
    </div>

  </div>
);

export default Footer;
