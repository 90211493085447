import React from 'react';
// import { SubHeading } from '../../components';
import { images } from '../../constants';
import './Header.css';

const Header = () => (
  <div className="app__header app__wrapper section__padding" id="home">
    <div className="app__wrapper_info">
      {/* <SubHeading title="A Taste of Korea" /> */}
      <h1 className="app__header-h1">Q Korean Steak House</h1>
      <p className="p__opensans" style={{ margin: '2rem 0' }}>At Q Korean Steakhouse, we are committed to bringing you  authentic Korean BBQ. Fresh marinated and unseasoned meat are grilled in front of (or by) the customer for a unique experience. We are passionate and dedicated to sharing the food from our hearts to our community. </p>
      <button type="button" className="custom__button"><a href="#menu">Explore Menu</a></button>
      <div className="app__wrapper_info">
        <div className="app__wrapper-content">
          <p className="p__cormorant" style={{ color: '#DCCA87', margin: '2rem 0' }}>Opening Hours</p>
          <p className="p__opensans">Sun - Thur: 11:30 am - 10:00 pm</p>
          <p className="p__opensans">Fri - Sat: 11:30 am - 11:00 pm</p>
        </div>
        <div className="app__wrapper-content">
          <p className="p__cormorant" style={{ color: '#DCCA87', margin: '1rem 0' }}>Locations</p>
          <p className="p__opensans">Cumming, Ga: 872 Buford Rd, Cumming, GA 30041</p>
          <p className="p__opensans">Columbus, Ga: 2643 Manchester expressway
            Columbus ga 31904
          </p>
          <p className="p__cormorant" style={{ color: '#DCCA87' }}>Opening Summer 2023</p>
          <p className="p__opensans">Huntsville, Al: 6125 university drive suite b26
            Huntsville Al 35806
          </p>
        </div>
      </div>

    </div>

    <div className="app__wrapper_img">
      <img src={images.welcomeMinHtml} alt="header_img" />
    </div>
  </div>
);

export default Header;

