import React from 'react';
import { SubHeading, MenuItem } from '../../components';
import { data, images } from '../../constants';
import './SpecialMenu.css';

const SpecialMenu = () => (
  <div className=" app__bg app__specialMenu flex__center section__padding" id="menu">
    <div className="app__specialMenu-title">
      <h1 className="headtext__cormorant">MENU</h1>
      <SubHeading title="Lunch: 21$ | Dinner A: 31$ | Dinner B:36$" />
    </div>
    <div className="app__specialMenu-menu">
      <div className="app__specialMenu-menu_cocktails  flex__center">
        <p className="app__specialMenu-menu_heading">Lunch (Mon-Fri 11am-2pm)</p>
        <div className="app__specialMenu_menu_items_left">
          {data.wines.map((wine, index) => (
            <MenuItem key={wine.title + index} title={wine.title} price={wine.price} tags={wine.tags} />
          ))}
        </div>
        <div className="app__specialMenu-menu_img">
          <img src={images.MenuLunch} alt="menu__img" />
        </div>
      </div>
      <div className="app__specialMenu-menu_img">
        <div className="app__specialMenu-menu_cocktails  flex__center">
          <p className="app__specialMenu-menu_heading">Sides</p>
          <div className="app__specialMenu_menu_items  second_child">
            {data.TableSides.map((TableSides, index) => (
              <MenuItem key={TableSides.title + index} title={TableSides.title} price={TableSides.price} tags={TableSides.tags} />
            ))}
          </div>
          <div className="app__specialMenu-menu_img">
            <img src={images.MenuDrinks} alt="menu__img" />
          </div>
        </div>
      </div>

      <div className="app__specialMenu-menu_cocktails  flex__center">
        <p className="app__specialMenu-menu_heading">Dinner</p>
        <div className="app__specialMenu_menu_items">
          {data.dinnerMeats.map((dinnerMeats, index) => (
            <MenuItem key={dinnerMeats.title + index} title={dinnerMeats.title} price={dinnerMeats.price} tags={dinnerMeats.tags} />
          ))}
        </div>
        <div className="app__specialMenu-menu_img">
          <img src={images.MenuDinner} alt="menu__img" />
        </div>
      </div>
    </div>

  </div>
);

export default SpecialMenu;
