import images from './images';

const wines = [
  {
    title: 'BEEF',
    tags: 'Brisket, Bulgogi',
  },
  {
    title: 'PORK',
    tags: 'Pork Belly, Spicy Pork Belly, Soy Pork Belly, Spicy Pork Bulgogi, Garlic Pork Belly',
  },
  {
    title: 'CHICKEN',
    tags: 'Lemon Chicken, Spicy Chicken, Soy Sauce Chicken',
  },
];

const dinnerMeats = [
  {
    title: 'BEEF',
    tags: 'Q Steak, Spicy BBQ Steak, NY Stip, Brisket, Bulgogi, Hawaiian Bulgogi, Filet Mignon, L.A. Galbi Short Ribs (Dinner B Only)',
  },
  {
    title: 'PORK',
    tags: 'Pork Belly, Spicy Pork Belly, Soy Pork Belly, Spicy Pork Bulgogi, Garlic Pork Belly, Smoked Pork Belly',
  },
  {
    title: 'CHICKEN',
    tags: 'Lemon Chicken, Spicy Chicken, Soy Sauce Chicken',
  },
  {
    title: 'SEAFOOD',
    tags: 'Shrimp, Cajun Shrimp',
  },
];

const TableSides = [
  {
    title: 'KOREAN TABLE SPREAD',
    tags: 'Mu ssam, Mu chae, Pickled Cucumber, Broccoli, Kimchi, Rice Cake, Potato Salad, Chive & Onion Salad, Lettuce Wraps, Lettuce Salad, Steamed Rice, Beef Daikon Soup, Deep Fried Dumpling (Dinner Only)',
  },
  {
    title: 'SAUCES',
    tags: 'Chili Sauce, Ssam Jang, Yum Yum, Sesame Oil with Salt',
  },
  {
    title: 'SEASONAL FRUIT & VEGETABLES',
    tags: 'Oranges, Apples, Pineapples | Onion, Zucchini, Sweet Potatoes, Bell Peppers, Mushrooms',
  },
];

const cocktails = [
  {
    title: 'Aperol Sprtiz',
    price: '$20',
    tags: 'Aperol | Villa Marchesi prosecco | soda | 30 ml',
  },
  {
    title: "Dark 'N' Stormy",
    price: '$16',
    tags: 'Dark rum | Ginger beer | Slice of lime',
  },
  {
    title: 'Daiquiri',
    price: '$10',
    tags: 'Rum | Citrus juice | Sugar',
  },
  {
    title: 'Old Fashioned',
    price: '$31',
    tags: 'Bourbon | Brown sugar | Angostura Bitters',
  },
  {
    title: 'Negroni',
    price: '$26',
    tags: 'Gin | Sweet Vermouth | Campari | Orange garnish',
  },
];

const awards = [
  {
    imgUrl: images.award02,
    title: 'Bib Gourmond',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
  },
  {
    imgUrl: images.award01,
    title: 'Rising Star',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
  },
  {
    imgUrl: images.award05,
    title: 'AA Hospitality',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
  },
  {
    imgUrl: images.award03,
    title: 'Outstanding Chef',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
  },
];

export default { wines, cocktails, awards, dinnerMeats, TableSides };
