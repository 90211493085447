import React from 'react';
import { Newsletter, SubHeading } from '../../components';

const FindUs = () => (
  <div className="app__bg app__wrapper section__padding" id="contact">
    <div className="app__wrapper_info">
      <SubHeading title="Contact" />
      <h1 className="headtext__cormorant" style={{ marginBottom: '3rem' }}>Find Us</h1>
      <div className="app__wrapper-content">
        <p className="p__cormorant" style={{ color: '#DCCA87' }}>Address</p>
        <p className="p__opensans" style={{ marginBottom: '2rem' }}>872 Buford Rd, Cumming, GA, United States, Georgia</p>
        <p className="p__cormorant" style={{ color: '#DCCA87' }}>Email Address</p>
        <p className="p__opensans">qkoreanbbqcumming@gmail.com</p>
        <p className="p__cormorant" style={{ color: '#DCCA87' }}>Phone Number</p>
        <p className="p__opensans">Cumming, Ga: (770) 406-8441</p>
      </div>
    </div>

    <div className="app__wrapper_img">
      <Newsletter />
    </div>
  </div>
);

export default FindUs;
